import React from 'react';
import '../styles/MajGallery.css';
import images from '../db/majData';

function MajGallery() {
  return (
    <div className="maj-body" data-aos="fade-up">
      <div className='maj-container'>
        <ul>
          {images.map((image) => (
            <li key={image.id}>
              <a href="#!">
                <img src={image.image} alt={`Image ${image.id}`} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MajGallery;
