import Main from "../component/Main";
import '../styles/Home.css';
import Principe from "../component/Principe";
import HomeAbout from "../component/HomeAbout";
import Support from "../component/Support";
import MajGallery from "../component/MajGallery";

function Home(){
    return(
        <div className="home-body">
            <div className="home-container">
                <Main/> 
                <MajGallery/>
                <Principe/>
                <HomeAbout/>
                <Support/>
            </div>
        </div>
    );
}

export default Home;