import React from "react";
import "../styles/Footer.css";
import { CiFacebook } from "react-icons/ci";
import { PiTelegramLogoThin } from "react-icons/pi";

function Footer() {
    return (
        <div className="main-footer">
            <div className="footer-container">
                <div className="row">
                    <div className="col">
                        <h3>Policy</h3>
                        <ul className="list-unstyled">
                            <li>© 2024 Giorgi Pkhakadze</li>
                            <li>All Rights Reserved.</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>About Us</h4>
                        <ul className="list-unstyled">
                            <li>Website Rules</li>
                            <li>Policy/Privacy</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h4>Contact</h4>
                        <ul className="list-unstyled">
                            <li>Pkhakadze2025@mail.ru</li>
                            <li>+995 557 406 424</li>
                        </ul>
                    </div>
                </div>
                <div className="footer-social">
                    <div className="footer-inside">
                        <a className="footer-facebook" href='https://www.facebook.com/giorgi.pxakadze.395' target="__blank">
                            <CiFacebook fontSize={30} color="#4267B2"/>
                        </a>
                        <a className="footer-telegram" href='https://www.facebook.com/giorgi.pxakadze.395' target="__blank">
                            <PiTelegramLogoThin fontSize={30} color="#24A1DE"/>
                        </a>
                    </div>
                    <div className="footer-text">
                        <p>Giorgi Pkhakadze</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;