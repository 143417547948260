import '../styles/Loader.css';

function Loader() {
    return (
        <div className="loader-body">
            <div className="waviy">
                <span style={{ "--i": 1 }}>G</span>
                <span style={{ "--i": 2 }}>I</span>
                <span style={{ "--i": 3 }}>O</span>
                <span style={{ "--i": 4 }}>R</span>
                <span style={{ "--i": 5 }}>G</span>
                <span style={{ "--i": 6 }}>I</span>
                <br />
                <span style={{ "--i": 7 }}>P</span>
                <span style={{ "--i": 8 }}>K</span>
                <span style={{ "--i": 9 }}>H</span>
                <span style={{ "--i": 10 }}>A</span>
                <span style={{ "--i": 11 }}>K</span>
                <span style={{ "--i": 12 }}>A</span>
                <span style={{ "--i": 13 }}>D</span>
                <span style={{ "--i": 14 }}>Z</span>
                <span style={{ "--i": 15 }}>E</span>
            </div>
        </div>
    );
}

export default Loader;
