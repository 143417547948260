const majData = [
    {
      id: 1,
      image: "https://i.imgur.com/dAh6RK5.jpeg",
    },
    {
      id: 2,
      image: "https://i.imgur.com/ppi7ilL.jpeg",
    },
    {
      id: 3,
      image: "https://i.imgur.com/OCONgwX.jpeg",
    },
    {
      id: 4,
      image: "https://i.imgur.com/y33lYny.jpeg",
    },
    {
      id: 5,
      image: "https://i.imgur.com/SknDeEu.jpeg",
    },
    {
      id: 6,
      image: "https://i.imgur.com/dU7cMOR.jpeg",
    },
    {
      id: 7,
      image: "https://i.imgur.com/DhDb7zo.jpeg",
    }
  ];
  
  export default majData;
  