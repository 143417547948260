const artData = [
    {
      id: 1,
      src: "https://i.imgur.com/SknDeEu.jpeg",
      alt: "ART1",
      caption: "ART 1"
    },
    {
      id: 2,
      src: "https://i.imgur.com/y33lYny.jpeg",
      alt: "ART2",
      caption: "ART 2"
    },
    {
      id: 3,
      src: "https://i.imgur.com/ppi7ilL.jpeg",
      alt: "ART3",
      caption: "ART 3"
    },
    {
      id: 4,
      src: "https://i.imgur.com/OCONgwX.jpeg",
      alt: "ART4",
      caption: "ART 4"
    }
  ];
  
  export default artData;
  