import { GrNew } from "react-icons/gr";
import '../styles/Principe.css';
import Header from "./Header";

function Principe() {
    return (
        <div className="princ-body" data-aos="fade-up">
            <Header name="Essence"/>
            <div className="princ-container">
                <div className="princ-container-inside pc1">
                    <div className="princ-container-text">
                        <h2>My Story</h2>
                        <p>Embark on a journey through my artistic evolution, uncovering the inspirations and emotions that drive my work.</p>
                    </div>
                    <div className="princ-container-btn">
                        <GrNew />
                    </div>
                </div>
                <div className="princ-container-inside pc2">
                    <div className="princ-container-text">
                        <h2>Art Showcase</h2>
                        <p>Dive into a curated selection of my finest pieces, each telling its own unique story through vibrant visuals.</p>
                    </div>
                    <div className="princ-container-btn">
                        <GrNew />
                    </div>
                </div>
                <div className="princ-container-inside pc3">
                    <div className="princ-container-text">
                        <h2>Connect</h2>
                        <p>Reach out for collaborations, custom commissions, or simply to share your thoughts on my art.</p>
                    </div>
                    <div className="princ-container-btn">
                        <GrNew />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Principe;