import '../styles/Header.css';

function Header(props){
    return(
        <div className="header-body">
            <h1>{props.name}</h1>
        </div>
    );
}

export default Header;