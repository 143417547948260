import React, { useMemo } from 'react';
import artData from '../db/seData'; 
import '../styles/seGallery.css';

function SeGallery() {
    const images = useMemo(() => artData, [artData]);

    return (
        <div className="seGallery-body">
            <div className="seGallery">
                {images.map((art) => (
                    <img key={art.id} src={art.src} alt={art.alt} loading="lazy" />
                ))}
            </div>
        </div>
    );
}

export default SeGallery;
