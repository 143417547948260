import "../styles/About.css";
// import { FaPortrait } from "react-icons/fa";
// import { GiMountainRoad } from "react-icons/gi";
// import { SiPrivatedivision } from "react-icons/si";
// import { RiMemoriesFill } from "react-icons/ri";
// import Line from '../component/Line';
// import Faq from '../component/Faq';
import GiorgiImage1 from '../assets/giorgiImages/giorgiImage1.jpeg';

function About() {
    return (
        <>
            <div className="main-about-body">
                <div className="main-about-container" data-aos="fade-up">
                    <div className="about-image">
                        <img src={GiorgiImage1} alt="" />
                        <div className="about-image-text">
                            <h2>Artist</h2>
                            <h1>Giorgi Pkhakadze</h1>
                        </div>
                    </div>
                    <div className="main-about-header">
                        <h1>About Me</h1>
                        <p>I am giorgi Pkhakadze. I was born 2000y - 17 april in georgia(Kutaisi). Welcome to my artistic journey. My work is a passionate dedicated to creating evocative and compelling artwork that speaks to the soul.</p>
                        <p>With a focus on painting and drawing, my work explores themes of nature, animals, and travel, bringing a unique perspective to every piece. Each artwork is a reflection of my personal experiences and observations, aiming to evoke emotions and provoke thought.</p>
                        <p>Explore my gallery to see my latest creations and discover the stories behind each piece. I invite you to connect with my art and experience the beauty and depth of my artistic vision.</p>
                    </div>
                    {/* <div className="main-about-services">
                        <div className="about-services-text" data-aos="fade-up">
                            <h1>My Artistic Offerings</h1>
                            <p className="services-text-main-p">In addition to my personal creations, I provide a range of bespoke artistic services tailored to your unique vision. Whether you're seeking a personalized creation or looking to embark on a collaborative project, I offer a customized approach to bring your artistic aspirations to life. Allow me to translate your ideas into stunning visual expressions with artistry and care.</p>
                        </div>
                        <div className="about-services-system" data-aos="fade-up">
                            <div className="services-system-container">
                                <div className="system-container-header">
                                    <GiMountainRoad className="icon" color="white" fontSize={30}/>
                                    <h3>Visual Journeys</h3>
                                </div>
                                <div className="system-container-text">
                                    <p>Experience a transformative visual journey through art that captures the spirit and essence of your dreams and inspirations. Each creation is designed to immerse you in a world of imagination, reflecting the depth and nuance of your personal narrative.</p>
                                </div>
                            </div>
                            <div className="services-system-container">
                                <div className="system-container-header">
                                    <FaPortrait className="icon" color="white" fontSize={30}/>
                                    <h3>Essence Portraits</h3>
                                </div>
                                <div className="system-container-text">
                                    <p>Engage with the profound beauty of essence portraits that transcend mere representation. These artworks are crafted to capture the very soul and character of their subjects, offering a timeless tribute to the personalities and stories they embody.</p>
                                </div>
                            </div>
                            <div className="services-system-container">
                                <div className="system-container-header">
                                    <RiMemoriesFill className="icon" color="white" fontSize={30}/>
                                    <h3>Epic Memories</h3>
                                </div>
                                <div className="system-container-text">
                                    <p>Celebrate your most cherished moments with epic memories captured through bespoke artistry. Each piece is a unique reflection of your experiences, transformed into evocative visuals that immortalize the essence of your adventures and personal stories.</p>
                                </div>
                            </div>
                            <div className="services-system-container">
                                <div className="system-container-header">
                                    <SiPrivatedivision className="icon" color="white" fontSize={30}/>
                                    <h3>Gourmet Visions</h3>
                                </div>
                                <div className="system-container-text">
                                    <p>Explore the art of gourmet visions, where culinary beauty meets artistic expression. My works celebrate the aesthetics of fine dining and casual fare alike, blending the visual appeal of food with creative artistry to present a feast for the eyes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-about-process" data-aos="fade-up">
                        <div className="about-process-header">
                            <h1>My Creative Process</h1>
                        </div>
                        <div className="about-process-system">
                            <div className="process-system-number">
                                <h3>01</h3>
                                <h3>Conceptualization</h3>
                            </div>
                            <div className="process-system-header">
                                <p>The first step involves developing ideas and concepts for the artwork. This stage includes brainstorming and gathering inspiration to form the foundation of the creative process.</p>
                                <Line/>
                            </div>
                            <div className="process-system-number">
                                <h3>02</h3>
                                <h3>Sketching</h3>
                            </div>
                            <div className="process-system-header">
                                <p>Creating preliminary sketches and drafts allows for visualization and refinement of the ideas. This stage is crucial for planning the composition and details of the final piece.</p>
                                <Line/>
                            </div>
                            <div className="process-system-number">
                                <h3>03</h3>
                                <h3>Painting</h3>
                            </div>
                            <div className="process-system-header">
                                <p>Applying paint and adding details to the artwork brings the vision to life. This stage involves layering colors, textures, and intricate details to complete the piece.</p>
                                <Line/>
                            </div>
                            <div className="process-system-number">
                                <h3>04</h3>
                                <h3>Finishing Touches</h3>
                            </div>
                            <div className="process-system-header">
                                <p>Final adjustments and preparation for display ensure the artwork is ready for presentation. This stage includes adding any last details and preparing the piece for framing or exhibition.</p>
                            </div>
                        </div>
                    </div>
                    <div className="about-faq-container" data-aos="fade-up">
                        <h1>Frequently Asked Questions</h1>
                        <Faq/>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default About;