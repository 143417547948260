const artData = [
    {
      id: 1,
      src: "https://i.imgur.com/DhDb7zo.jpeg",
      alt: "ART1",
      caption: "ART 1"
    },
    {
      id: 2,
      src: "https://i.imgur.com/dU7cMOR.jpeg",
      alt: "ART2",
      caption: "ART 2"
    },
    {
      id: 3,
      src: "https://i.imgur.com/SknDeEu.jpeg",
      alt: "ART3",
      caption: "ART 3"
    },
    {
      id: 4,
      src: "https://i.imgur.com/y33lYny.jpeg",
      alt: "ART4",
      caption: "ART 4"
    },
    {
      id: 5,
      src: "https://i.imgur.com/OCONgwX.jpeg",
      alt: "ART5",
      caption: "ART 5"
    },
    {
      id: 6,
      src: "https://i.imgur.com/ppi7ilL.jpeg",
      alt: "ART6",
      caption: "ART 6"
    },
    {
      id: 7,
      src: "https://i.imgur.com/dAh6RK5.jpeg",
      alt: "ART7",
      caption: "ART 7"
    },
    {
      id: 8,
      src: "https://i.imgur.com/CFLNfyJ.jpeg",
      alt: "ART8",
      caption: "ART 8"
    },
    {
      id: 9,
      src: "https://i.imgur.com/7xC0eSB.jpeg",
      alt: "ART9",
      caption: "ART 9"
    },
    {
      id: 10,
      src: "https://i.imgur.com/GtGgPro.jpeg",
      alt: "ART10",
      caption: "ART 10"
    },
    {
        id: 11,
        src: "https://i.imgur.com/vARIQsi.jpeg",
        alt: "ART11",
        caption: "ART 11"
    },
    {
        id: 12,
        src: "https://i.imgur.com/U2HDLiH.jpeg",
        alt: "ART12",
        caption: "ART 12"
    },
    {
        id: 13,
        src: "https://i.imgur.com/k2h2Swa.jpeg",
        alt: "ART13",
        caption: "ART 13"
    },
    {
        id: 14,
        src: "https://i.imgur.com/Txxzlkq.jpeg",
        alt: "ART14",
        caption: "ART 14"
    },
    {
        id: 15,
        src: "https://i.imgur.com/J39K7ff.jpeg",
        alt: "ART15",
        caption: "ART 15"
    },
    {
      id: 16,
      src: "https://i.imgur.com/pt27NdD.jpeg",
      alt: "ART15",
      caption: "ART 15"
  }
  ];
  
  export default artData;
  