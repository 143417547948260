import '../styles/Main.css';
import { GiJourney } from "react-icons/gi";
import { IoCallOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

function Main(){
    return(
        <div className="main-body">
            <div className="main-container" data-aos="fade-up">
                <div className="main-text">
                    <h1>Creativity, Unleashed</h1>
                    <p>Art is the silent language of the soul, conveying emotions words cannot express.</p>
                </div>
                <div className="main-button">
                    <Link to="/contact"><button className='main-contact-btn'>Contact <span className='btn-gal'><IoCallOutline /></span></button></Link>
                    <Link to="/about"><button className='main-gallery-btn'>About <span className='btn-gal'><GiJourney/></span></button></Link>
                </div>
            </div>
        </div>
    );
}

export default Main;