import { BiSolidErrorAlt } from "react-icons/bi";
import '../styles/Error.css';

function Error(){
    return(
        <div className="error-body">
            <div className="error-container">
                <h1>The images have not been uploaded yet.</h1>
                <BiSolidErrorAlt fontSize={80}/>         
            </div>
        </div>
    );
}

export default Error;