import './App.css';
import Navbar from './navbar/Navbar';
import Home from './pages/Home';
import Footer from './footer/Footer';
import { Route, Routes } from 'react-router';
import Contact from './pages/Contact';
import About from './pages/About';
import Gallery from './pages/Gallery';
import ScrollToTop from './component/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import Loading from './component/Loader';
import Error from './pages/Error';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AOS.init({
        duration: 1200,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  if (isLoading) {
    return <Loading />;
  }


  return (
    <>
      <ScrollToTop />
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path='/about' element={<About/>}/>
        <Route path='/gallery/collage' element={<Gallery/>}/>
        <Route path='/error' element={<Error/>}/>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
