import '../styles/HomeAbout.css';
import SeGallery from './SeGallery';

function HomeAbout(){
    return(
        <div className="homeAbout-body">
            <div className="homeAbout-container" data-aos="fade-up">
                <div className="homeAbout-header">
                    <h1>About My Artistic Journey</h1>
                </div>
                <div className="homeAbout-inside">
                    <div className="homeAbout-text">
                        <div className="ha-text-header">
                            <p>My Journey</p>
                        </div>
                        <div className="ha-text-text">
                            <p>
                                My name is Giorgi Pxakadze, and my journey into the world of art began at a young age. Growing up, I was always fascinated by colors, shapes, and the ability to express emotions through visual means. 
                                As I matured, this fascination turned into a deep passion for painting. I spent countless hours honing my skills, experimenting with different mediums, and studying the works of master artists. 
                                I invite you to explore my gallery, where you can see my latest creations and delve into the stories behind each artwork.
                            </p>
                        </div>
                    </div>
                    <div className="homeAbout-video">
                        <SeGallery/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeAbout;
