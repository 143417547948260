import '../styles/Contact.css';
import { IoMailOpenOutline } from "react-icons/io5";
import { CiMobile4 } from "react-icons/ci";
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

function Contact(){
    const [userData, setUserData] = useState({ Name: '', Email: '', Message: '' });
    const [alert, setAlert] = useState({ show: false, message: '', variant: '' });

    let name, value;
    const data = (e) => {   
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    };

    const send = async (e) => {
        e.preventDefault();
        const { Name, Email, Message } = userData;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(Email)) {
            setAlert({ show: true, message: "Please enter a valid email address.", variant: 'danger' });
            return;
        }

        if (Message.length < 10) {
            setAlert({ show: true, message: "Message should be at least 10 characters long.", variant: 'danger' });
            return;
        }

        const option = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ Name, Email, Message })
        };

        const res = await fetch('https://tvishi-tour-default-rtdb.firebaseio.com/Messages.json', option);

        if (res.ok) {
            setAlert({ show: true, message: "Your message has been sent successfully!", variant: 'success' });
            setUserData({ Name: '', Email: '', Message: '' });
        } else {
            setAlert({ show: true, message: "Failed to send your message. Please try again later.", variant: 'danger' });
        }
    };

    return(
        <div className="contact-body" data-aos="fade-up">
            <div className="contact-container">
                <div className="contact-header">
                    <h1>Contact</h1>
                </div>
                <div className="contact-form">
                    <form className="contact-text-area" method="POST">
                        <input onChange={data} autoComplete="off" name="Email" type="email" value={userData.Email} className="email-input" placeholder="Enter a valid email address"/>
                        <input onChange={data} autoComplete="off" name="Name" type="text" value={userData.Name} className="name-input" placeholder="Enter your name"/>
                        <textarea onChange={data} autoComplete="off" name="Message" value={userData.Message} placeholder="Enter text"></textarea>
                        <button onClick={send}>Submit</button>
                    </form>
                </div>
                <div className="alert-container">
                    {alert.show && (
                        <Alert variant={alert.variant} onClose={() => setAlert({ show: false, message: '', variant: '' })} dismissible>
                            {alert.message}
                        </Alert>
                    )}
                </div>
                <div className="contact-info">
                    <div className="contact-info-inside cii1">
                        <IoMailOpenOutline fontSize={70}/>
                        <h2>Electronic Mail</h2>
                        <h3>g.pxakadze@gmail.com</h3>
                    </div>
                    <div className="contact-info-inside cii2">
                        <CiMobile4 fontSize={70}/>
                        <h2>Mobile Number</h2>
                        <h3>+995 557 406 424</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;