import SupportImage from '../assets/images/supportImage.jpg';
import '../styles/Support.css';
import Header from './Header';
import { GiReceiveMoney } from "react-icons/gi";
import { SiLitecoin } from "react-icons/si";
import { FaCcMastercard } from "react-icons/fa";
import { FaCcVisa } from "react-icons/fa";
import { FaPaypal } from "react-icons/fa";

function Support(){
    return(
        <div className="support-body" data-aos="fade-up">
            <Header name="Support artist"/>
            <div className="support-container">
                <div className="support-img">
                    <img src={SupportImage} alt="supportImage" />
                </div>
                <div className="support-text-section">
                    <h4>Please support the artist</h4>
                    <p>Any amount you choose to donate would be greatly appreciated</p>
                    <p><FaPaypal /> <FaCcMastercard /> <FaCcVisa /> <SiLitecoin /> </p>
                    <button><GiReceiveMoney/> Donate now</button>
                </div>
            </div>
        </div>
    );
}

export default Support;