import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {FaFacebook} from "react-icons/fa";
import '../styles/Navbar.css';
import { FaTelegram } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

function CustomNavbar() {
  return (
    <Navbar expand="lg" className='navbar fixed-top'>
      <Container>
        <Navbar.Brand as={Link} to="/">Giorgi Pkhakadze</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">About</Nav.Link>
            <NavDropdown title="Gallery" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/gallery/collage">Collage</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/error">Graphic-sketches</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/error">Color</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/error">Mix-Media</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
          </Nav>
          <Nav className='ms-auto'>
            <div className='xyz'>
              <a href='https://www.facebook.com/giorgi.pxakadze.395' target='__blank'>
                <FaFacebook color='#FFFFFF' fontSize={35} />
              </a>
              <a href='https://t.me/giopkhakadze' target='__blank'>
                <FaTelegram color='#FFFFFF' fontSize={35} />
              </a>
              <a href='mailto:Pkhakadze2025@mail.ru'>
                <SiGmail color='#FFFFFF' fontSize={35} />
              </a>
            </div>
            {/* <Nav.Link href='https://www.facebook.com' target="_blank">
              <AiFillInstagram color='#FFFFFF' fontSize={30}/>
            </Nav.Link>
            <Nav.Link href='https://www.facebook.com' target="_blank">
              <FaTelegram color='#FFFFFF' fontSize={30}/>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;