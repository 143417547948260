import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import artData from '../db/artData'; // Adjust the path as needed
import '../styles/Gallery.css';

function Gallery() {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const handleShow = (item) => {
    setSelectedImage(item);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <div className="gallery-body" data-aos="fade-up">
      <div className="grid-container">
        {artData.map(item => (
          <div key={item.id} onClick={() => handleShow(item)} >
            <img className={`grid-item grid-item-${item.id}`} src={item.src} alt={item.alt} />
            <p>{item.caption}</p>
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="custom-modal-header">
          <Modal.Title className="modal-title">{selectedImage.caption}</Modal.Title>
          <button className="custom-close-button" onClick={handleClose}>X</button>
        </Modal.Header>
        <Modal.Body>
          <img className="img-fluid" src={selectedImage.src} alt={selectedImage.alt} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Gallery;
